exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-how-to-play-tsx": () => import("./../../../src/pages/how-to-play.tsx" /* webpackChunkName: "component---src-pages-how-to-play-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-message-box-tsx": () => import("./../../../src/pages/message/box.tsx" /* webpackChunkName: "component---src-pages-message-box-tsx" */),
  "component---src-pages-message-create-tsx": () => import("./../../../src/pages/message/create.tsx" /* webpackChunkName: "component---src-pages-message-create-tsx" */),
  "component---src-pages-message-index-tsx": () => import("./../../../src/pages/message/index.tsx" /* webpackChunkName: "component---src-pages-message-index-tsx" */),
  "component---src-pages-parallel-experiment-tsx": () => import("./../../../src/pages/parallel-experiment.tsx" /* webpackChunkName: "component---src-pages-parallel-experiment-tsx" */),
  "component---src-pages-press-kit-tsx": () => import("./../../../src/pages/press-kit.tsx" /* webpackChunkName: "component---src-pages-press-kit-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-redeem-tsx": () => import("./../../../src/pages/redeem.tsx" /* webpackChunkName: "component---src-pages-redeem-tsx" */),
  "component---src-pages-the-story-tsx": () => import("./../../../src/pages/the-story.tsx" /* webpackChunkName: "component---src-pages-the-story-tsx" */),
  "component---src-pages-unboxing-the-cryptic-killer-tsx": () => import("./../../../src/pages/unboxing-the-cryptic-killer.tsx" /* webpackChunkName: "component---src-pages-unboxing-the-cryptic-killer-tsx" */),
  "component---src-pages-unsolved-case-tsx": () => import("./../../../src/pages/unsolved-case.tsx" /* webpackChunkName: "component---src-pages-unsolved-case-tsx" */),
  "component---src-pages-web-games-faq-tsx": () => import("./../../../src/pages/web-games/faq.tsx" /* webpackChunkName: "component---src-pages-web-games-faq-tsx" */),
  "component---src-pages-web-games-parallel-lab-tsx": () => import("./../../../src/pages/web-games/parallel-lab.tsx" /* webpackChunkName: "component---src-pages-web-games-parallel-lab-tsx" */),
  "component---src-pages-web-games-unboxing-the-mind-of-a-cryptic-killer-tsx": () => import("./../../../src/pages/web-games/unboxing-the-mind-of-a-cryptic-killer.tsx" /* webpackChunkName: "component---src-pages-web-games-unboxing-the-mind-of-a-cryptic-killer-tsx" */),
  "component---src-templates-blog-post-blog-post-tsx": () => import("./../../../src/templates/BlogPost/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx" */)
}

